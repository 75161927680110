/* eslint-disable react/no-array-index-key */
import clsx from 'clsx';
import { Container } from '@/shared/ui/container';
import { tableItems } from './config/table.config';
import { Button } from '@/shared/ui/button';
import { listItems } from './config/list.config';
import { useLinks } from '@/shared/context/links.context';
import styles from './styles.module.scss';

export const Info = () => {
  const links = useLinks();

  return (
    <section className={styles.section}>
      <Container className={styles.container}>
        <div className={styles.wrapper}>
          <h1 className={styles.title}>C7 КАЗИНО (7К CASINO)</h1>
          <div className={styles.imageWrapper}>
            <img
              src="/images/site-screen.jpg"
              className={styles.image}
              alt="C7 КАЗИНО  (7К CASINO)"
            />
          </div>
          <div className={styles.table}>
            {tableItems.map((item) => (
              <div key={item.label} className={styles.row}>
                <span className={styles.label}>{item.label}</span>
                <span className={styles.value}>{item.value}</span>
              </div>
            ))}
          </div>
          <div className={styles.desc}>
            <p>
              7C casino появилось совсем недавно, з начале 2023 года, однако за
              короткий промежуток времени успело наработать огромную клиентскую
              базу. Для всех посетителей созданы комфортные условия игры. К
              запуску в казино 7C предлагается более 4,5 тыс. игр от известных
              брендов. Благодаря сотрудничеству с популярными сервисами оплаты
              для проведения денежных операций можно использовать одну из систем
              - Piastrix, банковские карты Visa/ MasterCard, МИР, виртуальный
              кошелек Ю-Мопеу или криптовалюту (Ethereum, Tether, Bitcoin).
            </p>
          </div>
          <h1 className={styles.title}>БОНУСЫ C7 КАЗИНО</h1>
          <div className={styles.galleryWrapper}>
            <div className={styles.galleryItem}>
              <img src="/images/bonuses-1.jpg" alt="БОНУСЫ C7 КАЗИНО" />
            </div>
            <div className={styles.galleryItem}>
              <img src="/images/bonuses-2.jpg" alt="БОНУСЫ C7 КАЗИНО" />
            </div>
          </div>
          <div className={styles.desc}>
            <p>
              <span>
                В казино 7К онлайн действует продуманная бонусная программа. В
                ее рамках авторизованные игроки могут активировать любое
                поощрение на выбор:
              </span>
              <span>Welcome-pack 110% и 80 ФС за депозит;</span>
              <span>эксклюзивный бонус и 70 ФС в слоте Triple Chilli;</span>
              <span>120% бонус за второе пополнение баланса;</span>
              <span>12% кэшбек.</span>
              <span>
                Также клиенты 7К казино могут участвовать в краткосрочных
                промоакциях и других мероприятиях, получая при этом
                дополнительные поощрения.
              </span>
            </p>
          </div>
          <h1 className={styles.title}>ИГРОВЫЕ АВТОМАТЫ 7С КАЗИНО</h1>
          <div className={styles.desc}>
            <p>
              <span>
                В коллекции казино 7К насчитывается более 4,5 тыс. игр. Это
                лицензионные разработки несколько десятков провайдеров мирового
                уровня. В 7К казино играть в них предлагается на реальные деньги
                или в тестовом демо-режиме. Предпочтительный вариант игры
                гемблер выбирает с учетом собственного банкролла.
              </span>
              <span>
                Игровые автоматы 7К — самая обширная категория игр. При помощи
                специальных фильтров их можно отсортировать по провайдеру,
                уровню RTP, популярности, новизне, рейтингу, в алфавитном
                порядке. Все игры доступны в круглосуточном режиме, поэтому в
                них клиенты казино 7К играть могут 24/7 со стационарного ПК или
                телефона/смартфона/айфона.
              </span>
            </p>
          </div>
          <h1 className={styles.title}>РЕГИСТРАЦИЯ И ВХОД</h1>
          <div className={clsx(styles.imageWrapper, styles.smallImageWrapper)}>
            <img
              src="/images/authorization.jpg"
              className={styles.image}
              alt="РЕГИСТРАЦИЯ И ВХОД"
            />
          </div>
          <div className={clsx(styles.desc, styles.textOffset)}>
            <p>
              Чтобы зарегистрироваться в онлайн казино 7К, пользователю нужно
              выполнить несколько шагов:
            </p>
            <ol className={styles.list}>
              {listItems.map((item, idx) => (
                <li className={styles.listItem} key={idx}>
                  {item}
                </li>
              ))}
            </ol>
          </div>
          <div className={styles.desc}>
            <p>
              Также выполнить вход в 7К казино можно по упрощенной схеме при
              помощи социальных сетей. Достаточно нажать на одну из иконок
              (Twitter, Vkontakte, Telegram, Tik ТОК, Яндекс, Одноклассники),
              после чего все необходимые данные подтянутся к профилю
              автоматически.
            </p>
          </div>
          <h1 className={styles.title}>7С КАЗИНО ЗЕРКАЛО</h1>
          <div className={styles.desc}>
            <p>
              Беспрепятственно играть в казино 7К без блокировок всем гемблерам
              предлагает рабочее зеркало игрового клуба. Это запасной сайт с
              аналогичным функционалом и возможностями, но видоизмененным именем
              домена. Найти рабочее зеркало можно несколькими способами — на
              сайтах-партнерах, тематических форумах, стримах блогеров, Также
              ссылку на зеркальный линк можно получить при обращении к
              операторам клиентской поддержки по E-mail.
            </p>
          </div>
          <h1 className={styles.title}>МОБИЛЬНАЯ ВЕРСИЯ</h1>
          <div className={styles.desc}>
            <p>
              У 7К казино онлайн есть удобная в использовании мобильная версия.
              Она обеспечивает вход на сайт интернет-клуба с телефона или
              смартфона и не требует загрузки дополнительного софта. Игровые
              автоматы адаптируются под небольшие экраны портативных гаджетов и
              корректно работают, без зависаний. Благодаря мобильной версии
              можно играть в видеослоты и выполнять другие действия в 7К казино
              в любом месте, где имеется стабильное интернет-соединение.
            </p>
          </div>
          <Button
            className={styles.btn}
            variant="blue"
            href={links?.welcome_bonus}
          >
            welcome бонус
          </Button>
        </div>
      </Container>
    </section>
  );
};
